<template>
  <div class="ly_give">
    <div class="ly_give_bg">
      <img :src="basicData.basicBg" alt="" style="width:100%" />
    </div>
    <div class="ly_give_nav">
      <div class="ly_give_type">
        <ul>
          <li
            v-for="(item, idx) in cpList"
            :key="idx"
            :class="[currentIdx == idx ? 'on' : '']"
            @click="switchList(item, idx)"
          >
            <a
              :href="'#/ly_give?currentIdx=' + idx"
              :class="[currentIdx == idx ? 'on' : '']"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="ly_give_col" v-for="(item, idx) in product" :key="idx">
        <div v-html="item.maincontent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCaseTypes, getZzCaseList, getPageMsg } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      basicData: {},
      cpList: [],
      product: [],
      currentIdx: 0,
      loading: false,
      form: {
        page: 1,
        pagesize: 8,
        type_id: '',
        project: ''
      }
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.currentIdx = this.$route.query.currentIdx
          this.form.type_id = this.cpList[this.currentIdx].id
          this.allProduct(this.form)
        } else {
          this.currentIdx = 0
          this.form.type_id = this.cpList[0].id
          this.allProduct(this.form)
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      let project = sessionStorage.getItem(this.changeData() + 'project')
      that.form.project = project
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.basicData = res.data.data.basic
        }
      })
      getCaseTypes(project).then(res => {
        if (res.data.code == 1) {
          that.cpList = res.data.data
          if (that.$route.query.currentIdx) {
            that.currentIdx = that.$route.query.currentIdx
            that.form.type_id = that.cpList[that.currentIdx].id
            that.allProduct(that.form)
          } else {
            that.currentIdx = 0
            that.form.type_id = that.cpList[0].id
            that.allProduct(that.form)
          }
        }
      })
    },
    switchList (e, i) {
      this.currentIdx = i
      this.form.type_id = e.id
      this.allProduct(this.form)
    },
    allProduct (e) {
      this.loading = true
      getZzCaseList(e).then(res => {
        if (res.data.code == 1) {
          this.product = res.data.data.list
          this.loading = false
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.ly_give {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .ly_give_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .ly_give_nav {
    margin: 0.5rem auto;
    width: 1100px;
    .ly_give_type {
      text-align: center;
      padding: 20px 0;
      ul {
        display: flex;
        justify-content: center;
        li {
          display: block;
          color: #373737;
          margin: 0 10px;
          cursor: pointer;
          white-space: nowrap;
          a {
            display: block;
            padding: 10px 30px;
            border-radius: 30px;
          }
        }
        li {
          .on,
          a:hover {
            background-color: #e62022;
            color: #fff;
          }
        }
      }
    }
    .ly_give_col {
      margin-top: 0.5rem;
    }
  }
}
</style>
